import React from 'react';
import SkillsRadar from './SkillsRadar';
export function SkillsSection() {
  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="skills"
    >
      <div className="w-100">
        <h2 className="mb-5">Skills</h2>
        <div className="subheading mb-3">Programming Languages &amp; Tools</div>

        <div style={{ height: '500px' }}>
          <SkillsRadar></SkillsRadar>
        </div>
        <div className="subheading mb-3">My Development Tools</div>
        <ul className="fa-ul mb-0">
          <li>
            <i className="fa-li fa fa-check"></i>
            Git, Source Control
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            Intellij IDEA, Enterprise Java Projects
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            VS Code, Frontend and Node.js Development
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            Postman, REST APIs testing
          </li>
        </ul>

        <div className="subheading mb-3">Workflow</div>
        <ul className="fa-ul mb-0">
          <li>
            <i className="fa-li fa fa-check"></i>
            Mobile-First, Responsive Design Web Applications
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            REST APIs Design &amp; Implementation with Express or AWS API
            Gateway
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            Unit Testing - No matter the programming language I use, I do always
            provide unit test for software I deliver to others.
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            Cross Browser Testing &amp; Debugging
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            Cross Functional Teams
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            Agile Development &amp; Scrum
          </li>
        </ul>

        <div className="subheading mb-3">Spoken Languages</div>
        <ul className="fa-ul mb-0">
          <li>
            <i className="fa-li fa fa-check"></i>
            Italian - Native
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            English - C1
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            French - B2
          </li>
          <li>
            <i className="fa-li fa fa-check"></i>
            German - B1
          </li>
        </ul>
      </div>
    </section>
  );
}
