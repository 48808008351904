import React from 'react';
import { ResponsivePie } from '@nivo/pie';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const data = [
  {
    id: 'react',
    label: 'React.js',
    value: 80,
  },
  {
    id: 'node',
    label: 'Node.js',
    value: 70,
  },
  {
    id: 'html-sass',
    label: 'HTML & CSS/Sass',
    value: 80,
  },
  {
    id: 'javascript/typescript',
    label: 'Javascript & Typescript',
    value: 100,
  },
  {
    id: 'mongodb',
    label: 'MongoDB',
    value: 40,
    color: 'hsl(42, 70%, 50%)',
  },
  {
    id: 'sql',
    label: 'SQL',
    value: 20,
    color: 'hsl(42, 70%, 50%)',
  },
  {
    id: 'java-spring',
    label: 'Java & Spring',
    value: 25,
  },
  {
    id: 'python',
    label: 'Python',
    value: 20,
  },
  {
    id: 'docker',
    label: 'Docker',
    value: 20,
  },
  {
    id: 'aws',
    label: 'AWS',
    value: 40,
  },
  {
    id: 'dart-flutter',
    label: 'Dart & Flutter',
    value: 40,
  },
];
const SkillsRadar = () => (
  <ResponsivePie
    isInteractive={false}
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    colors={{ scheme: 'blues' }}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    enableSlicesLabels={false}
    radialLabel={data => {
      return data.label;
    }}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    onClick={x => {
      console.log(x);
    }}
  />
);

export default SkillsRadar;
