import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
import { SkillsSection } from '../components/SkillsSection';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} {config.phone}
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            I am experienced in leveraging agile frameworks to provide reliable
            and scalable solutions, without upfront over-engineering but
            fostering continous refactoring. Iterative approaches to corporate
            strategy foster collaborative thinking to further the overall value
            proposition.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a target="_blank" key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Senior Software Engineer</h3>
              <div className="subheading mb-3">CREALOGIX Group</div>
              <p>
                The company is switching all the product offering to leverage a
                React-based ecosystem. Therefore my role involves a lot of
                Frontend development focused on delivering widgets for the next
                generation e-banking platform, in collaboration with other teams
                working across different locations outside of Switzerland. On
                top of that I am also quite involved in pre-sales activities,
                mainly Customer meetings in which I prepare and present Frontend
                Solutions proposals adapted to the specific customer
                infrastructure. Additionally I also lead the development of
                Innovation projects. In those projects, together with the rest
                of team we try new UI/UX concepts and also different
                technological stacks. It's thanks to those innovation activities
                that I gathered excperience with AWS and delivered proof of
                concepts entirely based on a serverless model.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 2019 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">
                Software Engineer / Senior Software Engineer
              </h3>
              <div className="subheading mb-3">Appway</div>
              <p>
                After a short period of pure backend development (6 months) with
                Java 8, the company allowed me to switch to a more
                frontend-focused development role, until I reached a stage where
                I was able to do both proficiently and according to the company
                code quality standards. After a while, 80% of my capacity at
                Appway involved Frontend development of new widgets and features
                for the next product release (happening once per year). The
                remaining capacity was split between platform modernization
                activities ( I was part of the guild leading the transformation
                of the platform to a more modern technological stack for the
                frontend, based on React.js), and mentoring. I had the chance to
                follow new joiners and other colleagues interested in Frontend
                development, organizing both internal and external workshops and
                meetups.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">April 2016 - June 2019</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Development Engineer / Technical Lead</h3>
              <div className="subheading mb-3">Swissquote</div>
              <p>
                I started in Swissquote as Java Engineer, mainly responsible for
                development of new features for the company trading platform. My
                tasks included for 90% Java development with Spring but also
                some bug fix and small enhancements on the Frontend. After a
                while, thanks to the knowledge I acquired on the system I was
                working on, I got the chance to move towards a Tech Lead
                position In which I was mainly responsible of removing technical
                impediments for the rest of the team and coordinate integration
                activities with other development teams. During my time at
                Swissquote I got exposed a lot to problems and respective
                solutions around retrieving frequently updating data and how to
                display those data in realtime on the frontend.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2014 - April 2016</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer</h3>
              <div className="subheading mb-3">Deloitte</div>
              <p>
                After successfully delivering the first and only ASP.NET C#
                project of my life, I moved to a permanent Java role. There I
                got to chance to get exposed to the banking world, and I was
                mainly realizing data integration workflows with external
                banking systems (mainly COBOL applications) involving multi-step
                ETL processing. I also managed to get a lot of expertise around
                Reporting libraries (mainly Jasper Report) and techniques in
                order to produce highly customized reports to different type of
                knowledge workers interacting with our systems.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                February 2013 - September 2014
              </span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">IT Consultant</h3>
              <div className="subheading mb-3">Technology Advising</div>
              <p>
                My first IT-related job. It was a temporary position I started
                right after the university and I was responsible for fixing bugs
                on the front office web application of the bank "Cariparma
                Crédit Agricole".
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                September 2012 - February 2013
              </span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">University of Rome UNINETTUNO</h3>
              <div className="subheading mb-3">Master of Science (MSc)</div>
              <div>Computer Science and Engineering.</div>
              <p>110/110 in the Italian University Grade System</p>
              <p>Started and obtained while working full time</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2014 - November 2017</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">University of Salerno</h3>
              <div className="subheading mb-3">
                Bachelor of Engineering (B.Eng.)
              </div>
              <div>Computer Science and Engineering</div>
              <p>109/110 in the Italian Academic Grading System</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2009 - February 2012</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <SkillsSection></SkillsSection>
      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            Apart from being a software engineer, I enjoy part of my time being
            outdoors. In the winter, I am an decent snowboarder. During the
            warmer months here in Switzerland, I enjoy hiking, paddle and
            chilling by the Rhein river or Zürich lake.
          </p>
          <p className="mb-0">
            When indoors, I follow a number of different genre movies and
            television shows, and love spending time together with my girlfriend
            and friends. I do play the guitar and I do practice Taekwon-Do twice
            per week.
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Awards &amp; Certifications</h2>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              AWS Certified Developer - Associate
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              Professional Scrum Master I (scrum.org)
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              Grade 10 - Graded Examination in Spoken English - Trinity College
              London
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
